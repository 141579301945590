exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-adm-admon-pedidos-js": () => import("./../../../src/pages/adm/admon-pedidos.js" /* webpackChunkName: "component---src-pages-adm-admon-pedidos-js" */),
  "component---src-pages-adm-pedidos-js": () => import("./../../../src/pages/adm/pedidos.js" /* webpackChunkName: "component---src-pages-adm-pedidos-js" */),
  "component---src-pages-adm-productos-bazar-js": () => import("./../../../src/pages/adm/productos-bazar.js" /* webpackChunkName: "component---src-pages-adm-productos-bazar-js" */),
  "component---src-pages-adm-productos-mkp-face-js": () => import("./../../../src/pages/adm/productos-mkp-face.js" /* webpackChunkName: "component---src-pages-adm-productos-mkp-face-js" */),
  "component---src-pages-buscar-js": () => import("./../../../src/pages/buscar.js" /* webpackChunkName: "component---src-pages-buscar-js" */),
  "component---src-pages-buscar-remates-js": () => import("./../../../src/pages/buscar-remates.js" /* webpackChunkName: "component---src-pages-buscar-remates-js" */),
  "component---src-pages-buscar-remates-old-js": () => import("./../../../src/pages/buscar-remates-old.js" /* webpackChunkName: "component---src-pages-buscar-remates-old-js" */),
  "component---src-pages-canasta-compra-js": () => import("./../../../src/pages/canasta-compra.js" /* webpackChunkName: "component---src-pages-canasta-compra-js" */),
  "component---src-pages-cotizacion-pedido-uuid-js": () => import("./../../../src/pages/cotizacion/[pedido_uuid].js" /* webpackChunkName: "component---src-pages-cotizacion-pedido-uuid-js" */),
  "component---src-pages-cotizador-js": () => import("./../../../src/pages/cotizador.js" /* webpackChunkName: "component---src-pages-cotizador-js" */),
  "component---src-pages-deploys-js": () => import("./../../../src/pages/deploys.js" /* webpackChunkName: "component---src-pages-deploys-js" */),
  "component---src-pages-dev-cat-novedades-js": () => import("./../../../src/pages/dev/cat-novedades.js" /* webpackChunkName: "component---src-pages-dev-cat-novedades-js" */),
  "component---src-pages-dev-catalogo-impresion-js": () => import("./../../../src/pages/dev/catalogo-impresion.js" /* webpackChunkName: "component---src-pages-dev-catalogo-impresion-js" */),
  "component---src-pages-dev-catalogo-productos-impresion-js": () => import("./../../../src/pages/dev/catalogo-productos-impresion.js" /* webpackChunkName: "component---src-pages-dev-catalogo-productos-impresion-js" */),
  "component---src-pages-dev-firebase-test-js": () => import("./../../../src/pages/dev/firebase-test.js" /* webpackChunkName: "component---src-pages-dev-firebase-test-js" */),
  "component---src-pages-dev-imprimir-catalogos-js": () => import("./../../../src/pages/dev/imprimir-catalogos.js" /* webpackChunkName: "component---src-pages-dev-imprimir-catalogos-js" */),
  "component---src-pages-dev-post-post-id-js": () => import("./../../../src/pages/dev/post/[post_id].js" /* webpackChunkName: "component---src-pages-dev-post-post-id-js" */),
  "component---src-pages-dev-posts-cabbur-js": () => import("./../../../src/pages/dev/posts-cabbur.js" /* webpackChunkName: "component---src-pages-dev-posts-cabbur-js" */),
  "component---src-pages-dev-productos-cabbur-js": () => import("./../../../src/pages/dev/productos-cabbur.js" /* webpackChunkName: "component---src-pages-dev-productos-cabbur-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mi-cuenta-js": () => import("./../../../src/pages/mi-cuenta.js" /* webpackChunkName: "component---src-pages-mi-cuenta-js" */),
  "component---src-pages-solicitar-descuento-pedido-uuid-js": () => import("./../../../src/pages/solicitar-descuento/[pedido_uuid].js" /* webpackChunkName: "component---src-pages-solicitar-descuento-pedido-uuid-js" */),
  "component---src-pages-test-pages-js": () => import("./../../../src/pages/test-pages.js" /* webpackChunkName: "component---src-pages-test-pages-js" */),
  "component---src-pages-texto-descuento-js": () => import("./../../../src/pages/texto-descuento.js" /* webpackChunkName: "component---src-pages-texto-descuento-js" */),
  "component---src-pages-ticket-js": () => import("./../../../src/pages/ticket.js" /* webpackChunkName: "component---src-pages-ticket-js" */),
  "component---src-pages-ticket-venta-js": () => import("./../../../src/pages/ticket-venta.js" /* webpackChunkName: "component---src-pages-ticket-venta-js" */),
  "component---src-pages-ticket-venta-venta-uid-js": () => import("./../../../src/pages/ticket-venta/[venta_uid].js" /* webpackChunkName: "component---src-pages-ticket-venta-venta-uid-js" */),
  "component---src-pages-venta-detalle-js": () => import("./../../../src/pages/venta-detalle.js" /* webpackChunkName: "component---src-pages-venta-detalle-js" */),
  "component---src-pages-venta-detalle-venta-uid-js": () => import("./../../../src/pages/venta-detalle/[venta_uid].js" /* webpackChunkName: "component---src-pages-venta-detalle-venta-uid-js" */),
  "component---src-pages-ventas-js": () => import("./../../../src/pages/ventas.js" /* webpackChunkName: "component---src-pages-ventas-js" */),
  "component---src-pages-ver-pedido-pedido-uuid-js": () => import("./../../../src/pages/ver-pedido/[pedido_uuid].js" /* webpackChunkName: "component---src-pages-ver-pedido-pedido-uuid-js" */),
  "component---src-templates-catalogo-productos-js": () => import("./../../../src/templates/CatalogoProductos.js" /* webpackChunkName: "component---src-templates-catalogo-productos-js" */),
  "component---src-templates-post-cabbur-template-js": () => import("./../../../src/templates/PostCabburTemplate.js" /* webpackChunkName: "component---src-templates-post-cabbur-template-js" */),
  "component---src-templates-producto-detalle-js": () => import("./../../../src/templates/ProductoDetalle.js" /* webpackChunkName: "component---src-templates-producto-detalle-js" */)
}

